<template>
  <div class="page-immediate">
    <!-- 立即分期 -->
    <div class="firstBox">
      <!--      &lt;!&ndash; 合作医院 &ndash;&gt;-->
      <!--      <div class="hospital">-->
      <!--        <p class="top">-->
      <!--          <img src="./img/yiyuan.png" alt=""/>-->
      <!--          <span>合作医院</span>-->
      <!--        </p>-->
      <!--        <p class="bottom">-->
      <!--          <span v-if="partnerName">{{ partnerName }}</span>-->
      <!--          <span v-else>{{ partnerName1 }}</span>-->
      <!--        </p>-->
      <!--      </div>-->
      <!-- 合作医院 -->
      <div class="monthDigit">
        <van-field
          v-model="partnerName"
          is-link
          input-align="right"
          readonly
          name="picker"
          label="合作医院"
          placeholder=""
          @click="isShowPartnerPicker"
        />
        <van-popup v-model:show="showPartner" @close="cancelPartner" position="bottom">
          <van-picker
            value-key="realName"
            :columns="columns"
            :defaultIndex="colunmsIndex"
            @confirm="partnerCheck"
            @cancel="cancelPartner"
          />
        </van-popup>
      </div>

      <!-- 分期金额 -->
      <div class="divisionMoney">
        <van-field
          class="money"
          v-model="divMoney"
          @blur="contrastMoney"
          label="分期金额(元)"
          placeholder="请输入100的整数倍"
          input-align="right"
          clearable
          type="number"
        />
      </div>
      <!-- 客户经理 -->
      <div class="divisionEditBd">
        <van-field
          class="money"
          v-model="bdName"
          readonly
          @blur="editBD"
          label="客户经理"
          input-align="right"
          clearable
        />
        <div class="edit-bd">
          <van-icon v-if="isShowEdit && bdName" name="edit" size="18" />
        </div>
      </div>

      <!-- 选择期数 -->
      <div class="monthDigit">
        <van-field
          v-model="numberStage"
          is-link
          input-align="right"
          readonly
          name="picker"
          label="分期期数"
          placeholder=""
          @click="showNumber = true"
        />
        <van-popup v-model:show="showNumber" position="bottom">
          <van-picker
            value-key="value"
            :columns="numberData"
            @confirm="numberCheck"
            @cancel="showNumber = false"
          />
        </van-popup>
      </div>
    </div>
    <div class="secondBox">
      <!-- 申请项目 -->
      <div class="itemapply">
        <van-field
          is-link
          readonly
          name="picker"
          label="申请项目"
          input-align="right"
          placeholder=""
          v-model="itemNum"
          @click="showItem = true"
        />
        <van-popup v-model:show="showItem" position="bottom">
          <van-cascader
            v-model="itemValue"
            title="请选择美容项目"
            :options="options"
            @close="showItem = false"
            @finish="onFinish"
            :field-names="fieldNames"
          />
        </van-popup>
      </div>
      <div class="itemContent">
        <!-- 项目内容 -->
        <div
          class="itemContent_item"
          v-for="(item, index) in itemlist"
          :key="item"
        >
          <p class="itemName items">
            <span>{{ index + 1 }}.{{ item }}</span>
            <img @click="delItem(index)" src="./img/del.png" alt="" />
          </p>
        </div>
      </div>
      <div class="itemMoneys">
        <p class="itemMoney items">
          <van-field
            input-align="right"
            label="项目总金额（元）"
            v-model="itemMoney"
            clearable
            type="number"
            @blur="contrastMoney1"
            placeholder="请输入项目总金额"
          />
        </p>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottomBox">
      <div class="bottomLeft">
        <p class="bottom_top">
          <span class="every_title">每期应还：</span>
          <span class="money_red" v-if="shouldMoney"
            >￥{{ $filters.formatMoney(shouldMoney, 2) }}</span
          >
          <span class="money_red" v-else
            >￥{{ $filters.formatMoney(0, 2) }}</span
          >
        </p>
        <p class="bottom_bottom" @click="showBacklist">
          <span>查看还款计划</span>
          <van-icon class="toparrow" name="arrow-up" />
        </p>
      </div>
      <div class="bottomRight" @click="submit">提交</div>
    </div>
    <!-- 点击提交显示弹出层 -->
    <van-popup v-model:show="showPop">
      <div class="popup">
        <p class="poptext">
          医院、申请项目、项目金额、借款金额、借款期限一经提交将无法修改，请确认后再提交！
        </p>
        <div class="twoBtn">
          <p class="mysee" @click="mysee">我再看看</p>
          <p class="mysure" @click="mysure">我要提交</p>
        </div>
      </div>
    </van-popup>

    <!-- 点击查看还款计划 -->
    <div class="backplan">
      <van-popup v-model:show="showBackamt" position="bottom">
        <div class="backAmt">
          <p class="backTitle">
            <span>选择计划仅供参考，以实际放款时间及计划为准</span>
            <img @click="closeBacklist" src="./img/close.png" alt="" />
          </p>
          <div class="backList_item" v-for="item in moneyList" :key="item">
            <p>
              <span>第{{ item.planIndex }}期</span>
              <span class="gray">({{ item.planDate }})</span>
            </p>
            <p>
              <span>￥{{ item.planAmt }}</span>
            </p>
          </div>
        </div>
      </van-popup>
    </div>
    <!--输入BD的客户编号-->
    <van-dialog
      close-on-click-overlay
      v-model:show="isShowDialog"
      @confirm="onSubmit"
      title="绑定客户经理"
    >
      <div style="padding: 20px">
        <van-field
          v-model="editBdName"
          label="姓名:"
          placeholder="请输入客户经理姓名"
          input-align="right"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref } from "vue";
import API from "../../server/api";
import { useRouter, useRoute } from "vue-router";
import { Toast } from "vant";
import { isEmptyObj } from "@/utils/index";

export default {
  name: "ImmediateStaging",
  setup() {
    const Router = useRouter();
    const Route = useRoute();
    const colunmsIndex = ref(0);
    const state = reactive({
      divMoney: "",
      numberStage: "", // 分期期数
      bdName: "", // 客户经理
      showNumber: false, // 展示期数的下拉框
      showPartner: false, // 展示医院的下拉框
      numberData: [], // 期数列表
      showItem: false, // 展示项目列表
      itemData: ["jgg", "fff"], // 项目列表
      showPop: false, // 提示框
      showBackamt: false, // 还款计划
      itemValue: "",
      itemlist: [],
      itemIdList: [],
      moneyList: [], // 还款计划
      itemId: "",
      itemMoney: "", // 项目总金额
      partnerName1: "",
      partnerName: "",
      creditNo: "", // 授信单号
      userId: "",
      bdNo1: "",
      bdNo: "",
      partnerNo1: "",
      partnerNo: "",
      bdInfo: {},
      itemNum: "",
      shouldMoney: "",
      fieldNames: {
        text: "itemValue",
        value: "id",
        children: "children"
      },
      options: [],
      // 商户数据
      columns: [],
      key: null,
      creditType: null,
      isWithdrawal: null
    });
    const editBdName = ref("");

    // 借款试算
    const loanNum = async data => {
      try {
        const res = await API.getLoanNumV2(data);
        if (res.success) {
          state.moneyList = res.data;
          state.shouldMoney = res.data[0].planAmt;
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    // 点击确定显示期数
    const numberCheck = async value => {
      state.numberStage = value;
      state.showNumber = false;
      if (state.divMoney && state.numberStage) {
        const money = {};
        money.loanIntentionAmount = state.divMoney;
        money.loanIntentionNum = state.numberStage;
        money.partnerNo = state.partnerNo;

        // 计算那啥
        await loanNum(money);
      }
    };

    // 点击确定合作医院
    const partnerCheck = async value => {
      console.log(value);
      state.showPartner = false;
      state.partnerNo = value.partnerNo;
      state.partnerName = value.realName;
      if (state.divMoney && state.numberStage) {
        const money = {};
        money.loanIntentionAmount = state.divMoney;
        money.loanIntentionNum = state.numberStage;
        money.partnerNo = state.partnerNo;

        // 计算那啥
        await loanNum(money);
      }
    };

    // 失去焦点做判断
    const contrastMoney = () => {
      const DivMoney = Number(state.divMoney || 0);
      if (DivMoney % 100 !== 0) {
        Toast("请输入100的整数倍!");
        state.divMoney = "";
        return false;
      }
      if (DivMoney <= 0) {
        Toast("请输入有效金额!");
        state.divMoney = "";
        return false;
      }
      // 大于等于3000 小于等于 150000
      if (DivMoney < 3000 && DivMoney > 150000) {
        Toast("借款金额须在3000~150000之间!");
        state.divMoney = "";
        return false;
      }
      state.numberStage = "";
    };

    const contrastMoney1 = () => {
      if (state.itemMoney <= 0) {
        Toast("请输入有效金额！");
        state.itemMoney = "";
      }
    };

    // 点击确定显示项目
    const itemCheck = () => {
      state.showItem = false;
    };

    // 点击删除项目
    const delItem = n => {
      state.itemlist.splice(n, 1);
      state.itemIdList.splice(n, 1);
      state.itemNum = `已选择${state.itemlist.length}项`;
    };

    // 点击清空输入框
    const delInput = () => {
      state.divMoney = "";
    };
    // 点击提交
    const submit = () => {
      if (!state.divMoney) {
        Toast.fail("请输入分期金额");
        return;
      }
      if (!state.numberStage) {
        Toast.fail("请选择分期期数");
        return;
      }
      if (state.itemlist.length === 0) {
        Toast.fail("请选择申请项目");
        return;
      }
      if (!state.itemMoney) {
        Toast.fail("请输入项目总金额");
        return;
      }
      state.showPop = true;
    };

    // 点击我再看看
    const mysee = () => {
      // 提示款关闭
      state.showPop = false;
    };

    // 保存借款意向
    const saveLoan = async data => {
      try {
        const res = await API.saveLoan(data);
        if (res.success) {
          Toast.success("提交成功");
          setTimeout(function() {
            Router.go(-1);
          }, 1200);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    // 我要提交
    const mysure = async () => {
      const loanList = {};
      loanList.bdNo = state.bdNo ? state.bdNo : state.bdNo1;
      loanList.partnerNo = state.partnerNo ? state.partnerNo : state.partnerNo1;
      loanList.partnerName = state.partnerName
        ? state.partnerName
        : state.partnerName1;
      loanList.loanIntentionAmount = state.divMoney;
      loanList.loanIntentionNum = state.numberStage;
      loanList.projectId = state.itemIdList;
      loanList.projectName = state.itemlist;
      loanList.userId = state.userId;
      loanList.projectAmount = state.itemMoney;
      loanList.creditNo = state.creditNo;
      loanList.creditType = state.creditType;
      console.log(loanList, '提交的数据');
      await saveLoan(loanList);
    };

    // 点击查看还款计划
    const showBacklist = () => {
      if (!state.divMoney) {
        Toast.fail("填写借款金额");
        return false;
      }
      const DivMoney = Number(state.divMoney || 0);
      // 大于等于3000 小于等于 150000
      if (DivMoney < 3000 && DivMoney > 150000) {
        Toast.fail("借款金额须在3000~150000之间!");
        state.divMoney = "";
        return false;
      }
      if (!state.numberStage) {
        Toast.fail("选择借款期数");
        return false;
      }
      state.showBackamt = true;
    };
    // 点击❌关闭还款计划
    const closeBacklist = () => {
      state.showBackamt = false;
    };

    // 选择美容项目
    const onFinish = ({ selectedOptions }) => {
      state.showItem = false;
      state.itemValue = selectedOptions
        .map(option => option.itemValue)
        .join("-");
      state.itemId = selectedOptions.map(option => option.id).join("-");
      state.itemIdList.push(state.itemId.substr(-3, 3));
      console.log(state.itemIdList, "idid");
      state.itemlist.push(state.itemValue);
      state.itemNum = `已选择${state.itemlist.length}项`;
    };

    // 获取借款意向单基础信息
    const getLoanList = async data => {
      try {
        const result = await API.getLoanData({ creditNo: data });
        state.partnerNo1 = result.partnerNo;
        state.partnerNo = result.partnerNo;
        state.bdNo1 = result.bdNo;
        state.bdName = result.bdName;
        state.partnerName1 = result.partnerName;
        state.partnerName = result.partnerName;
        state.numberData = result.periods;
        state.options = result.personList;
      } catch (err) {
        console.log(err.message);
      }
    };

    // 查询意向单信息(反显)
    const getLastLoanData = async () => {
      const resultData = await API.intention();
      if (resultData) {
        state.divMoney = resultData.loanIntentionAmount;
        state.itemMoney = resultData.projectAmount;
        state.itemIdList = resultData.projectId;
        const moneys = {};
        moneys.loanIntentionAmount = state.divMoney;
        moneys.loanIntentionNum = resultData.loanIntentionNum;
        moneys.partnerNo = resultData.partnerNo;
        await loanNum(moneys);
        state.numberStage = resultData.loanIntentionNum;
        state.itemlist = resultData.projectName;
        const len = resultData.projectName.length;
        state.itemNum = `已选择${len}项`;
        state.bdNo1 = resultData.bdNo;
        state.partnerNo1 = resultData.partnerNo;
        state.partnerNo = resultData.partnerNo;
        state.partnerName1 = resultData.partnerName;
        // state.partnerName = resultData.partnerName
      }
    };

    const initMerchantList = async () => {
      try {
        const res = await API.initMerchantList({});
        if (res.success) {
          state.columns = res.result;
        }
      } catch (err) {
        console.log(err);
      }
    };
    const isShowEdit = ref(false);
    const isShowDialog = ref(false);
    // 页面加载，创建
    onMounted(async () => {
      isShowEdit.value = localStorage.getItem("TE") || "";
      // 初始化商户
      initMerchantList();
      // 从缓存当中拿值
      state.creditNo = localStorage.getItem("creditNo");
      state.userId = localStorage.getItem("userId");

      // 获取当前用户的一个状态
      const phone = localStorage.getItem("cellPhone");

      // 判断是不是提现
      const result = await API.isUser({ phone });
      state.isWithdrawal = result.isWithdrawal;

      // 初始化数据
      await getLoanList(state.creditNo);

      // 那啥
      if (!isEmptyObj(Route.query) && Route.query.key) {
        state.key = Route.query.key;
        state.creditType = Route.query.creditType;
        if (Route.query.key === "1" && Route.path === "/immediateStaging")
          await getLastLoanData();
      }
    });
    const editBD = () => {
      if (isShowEdit.value) {
        isShowDialog.value = true;
      }
    };
    const onSubmit = async () => {
      if (!editBdName.value) {
        Toast("请输入客户经理姓名");
        return;
      }
      try {
        let userName = editBdName.value;
        const { code, result } = await API.getBdInfo({ userName });
        if (code === '200') {
          state.bdNo1 = result.userId;
          state.bdName = result.userName;
        }
        editBdName.value = "";
      } catch (error) {
        console.log(error);
      }
    };
    const isShowPartnerPicker = () => {
      const arr = state.columns
      if (arr && arr.length) {
        const index = arr.findIndex(item => item.partnerNo === state.partnerNo)
        colunmsIndex.value = index || 0
        state.showPartner = true
        return
      }
      Toast('合作医院为空')
    }
    const cancelPartner = () => {
      state.showPartner = false
      colunmsIndex.value = 0
    }
    return {
      ...toRefs(state),
      partnerCheck,
      numberCheck,
      itemCheck,
      delInput,
      delItem,
      submit,
      mysee,
      mysure,
      showBacklist,
      closeBacklist,
      onFinish,
      contrastMoney,
      contrastMoney1,
      editBD,
      isShowEdit,
      isShowDialog,
      onSubmit,
      editBdName,
      colunmsIndex,
      isShowPartnerPicker,
      cancelPartner
    };
  }
};
</script>
<style lang="less" src="./index.less" scoped></style>

<style>
.immediate .divisionMoney .van-field__control {
  font-size: 36px;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #fe4040;
}

.page-immediate .itemMoneys .van-field__control {
  font-size: 36px;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #fe4040;
}

.page-immediate .divisionMoney .van-cell::after {
  border: none;
}

.page-immediate .divisionMoney .van-field__label {
  width: 30%;
  font-size: 28px;

  font-weight: 500;
  color: #1e1e1e;
}

.page-immediate .divisionMoney .van-cell__value {
  color: #bbbbbb;
}

.page-immediate .divisionMoney .van-field__control .van-field__control--right {
  font-size: 28px;
}

.page-immediate .divisionMoney .van-field {
  width: 100%;
  height: 100%;
  border: none;
}

.page-immediate .van-cell_label {
  font-size: 28px;

  font-weight: 500;
  color: #1e1e1e;
}

.page-immediate .van-cell__title.van-field__label {
  font-size: 28px;

  font-weight: 500;
  color: #1e1e1e;
}

.page-immediate .monthDigit .van-cell.van-cell--clickable.van-field {
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
}

.page-immediate .itemapply .van-cell.van-cell--clickable.van-field {
  /* height: 100%; */
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
}

.page-immediate .backplan .van-popup {
  background: none;
}

.page-immediate .backAmt {
  overflow-y: scroll;
}

.page-immediate .itemMoney .itemMoneys .van-cell__title.van-field__label {
  width: 40%;
}

.page-immediate .itemMoney .van-field__label {
  width: 40%;
}

.page-immediate .itemMoney .van-cell {
  padding: 0;
}

.page-immediate .itemapply .van-cell::after {
  border: none;
}

.page-immediate .backAmt .van-popup {
  position: initial;
  overflow-y: scroll;
  background: #fff;
}
</style>
